import { Link } from "gatsby"
import React from "react"
import { SRLWrapper } from "simple-react-lightbox"
import Banner from "../../components/Banner"
import Layout from "../../components/core/Layout"
import SEO from "../../components/core/SEO"
import Images from "../../components/Images"

const ScottHouseCCPage: React.FC = () => (
  <Layout activePage="projects">
    <SEO title="Scott House (Community Clay) Project" />
    <Banner>
      <p>
        <b>This project is now completed.</b>
        <br />
        Thanks to Transported and Scott House for all of their support and for
        funding this project.
        <br />
        <span className="timescale">
          This project ran from March 2021 to October 2021.
        </span>
      </p>
    </Banner>
    <h1 className="page-title">Scott House (Community Clay)</h1>
    <div className="community-clay__container">
      <div>
        <p>
          This project was an extension of the{" "}
          <Link to="/projects/community-clay">Community Clay</Link> project,
          designed and planned for people that attend and use the facilities of
          Scott House. The format and layout closely mirrored the approach taken
          for Community Clay.
          <br />
          The tiles are on display at the entrance of Scott House. This project
          was jointly funded by Transported and Scott House/Boston Borough
          Council.
        </p>
      </div>
      <SRLWrapper>
        <Images image="scotthouselarge" />
      </SRLWrapper>
    </div>
  </Layout>
)

export default ScottHouseCCPage
